import {Box, Button, HStack} from '@chakra-ui/react'
import useTranslation from 'next-translate/useTranslation'
import Link from 'next/link'
import {useRouter} from 'next/router'
import {
  ROUTES_ABOUT,
  ROUTES_CONTACT,
  ROUTES_FAQ,
  ROUTES_HOME,
  ROUTES_SEARCH,
  ROUTES_ONTOLOGY
} from '../../../constants/routes'

interface Props {
  link: string
  name: string
}

const RenderItem = ({link, name}: Props) => {
  const router = useRouter()

  return (
    <Link href={link} passHref>
      <Button variant='navLink' isActive={router.route === link}>
        {name}
      </Button>
    </Link>
  )
}

// #CHANGE_SECTIONS
const menuEntries = [
  {link: ROUTES_HOME, name: 'common:home'},
  {link: ROUTES_SEARCH(), name: 'common:search'},
  {link: ROUTES_FAQ, name: 'common:faq'},
  {link: ROUTES_ABOUT, name: 'common:about'},
  {link: ROUTES_ONTOLOGY, name: 'common:ontology'},
  {link: ROUTES_CONTACT, name: 'common:contact'}
]

const Menu = () => {
  const {t} = useTranslation()

  return (
    <Box flexGrow={1}>
      <HStack h='full' justifyContent='center'>
        {menuEntries.map(entry => (
          <RenderItem link={entry.link} name={t(entry.name)} key={entry.link} />
        ))}
      </HStack>
    </Box>
  )
}

export default Menu
